




















































































import { Component, Vue, Prop } from "vue-property-decorator";
import ExportData from "@/components/export-data/index.vue";
import DatePicker from "@/components/date-picker.vue";
import sales_line from "./components/sales_line.vue";

import { apiInformationSales } from "@/api/data_center";
import { timeFormat } from "@/utils/util";

@Component({
    components: {
        DatePicker,
        sales_line,
    },
})
export default class overview extends Vue {
    // S Data
    form: any = {
        start_time: "",
        end_time: "",
        type: 1,
    };
    buttonGroup = [
        {
            name: "日",
            type: 1,
        },
        {
            name: "周",
            type: 2,
        },
        {
            name: "月",
            type: 3,
        },
        {
            name: "年",
            type: 4,
        },
    ];
    loading = false;
    card_summary = {};
    chart_sale = {};
    product_profiles = [];
    // E Data

    // S Methods
    changeDate(e) {
        this.form.start_time = e[0];
        this.form.end_time = e[1];
        this.getData(this.form.type);
    }

    getData(type = 1) {
        this.form.type = type;
        this.loading = true;
        apiInformationSales(this.form)
            .then((res) => {
                this.loading = false;
                this.card_summary = res.card_summary;
                this.chart_sale = res.chart_sale;
                this.product_profiles = res.product_profiles;
            })
            .catch((err) => {
                this.loading = false;
            });
    }
    // E Methods

    // S  life cycle

    created() {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
        this.form.start_time = timeFormat(start, "yyyy-mm-dd");
        this.form.end_time = timeFormat(end, "yyyy-mm-dd");
        this.getData(1);
    }

    // E life cycle
}
